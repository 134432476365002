<template>
    <section class="marketing-cards-slider">
        <ContainerContent :align-left="true" class="slider-container">
            <div class="wrapper">
                <MarketingCardsSliderHeadings :heading="heading" :sub-heading="subHeading" />

                <SimpleSlider :items-count="3" :additional-scroll-offset="32" class="slider">
                    <template #slides>
                        <slot></slot>
                    </template>
                </SimpleSlider>
            </div>
        </ContainerContent>
    </section>
</template>

<script>
import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import SimpleSlider from '@molecules/SimpleSlider/SimpleSlider';
import MarketingCardsSliderHeadings from './MarketingCardsSliderHeadings';

export default {
    name: 'MarketingCardsSlider',

    components: {
        ContainerContent,
        MarketingCardsSliderHeadings,
        SimpleSlider,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        subHeading: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
$heading-height: 26px;
$sub-heading-height: 26px;
$headings-margin: 12px;

$navigation-buttons-height: 42px;

.marketing-cards-slider {
    @apply w-full;

    .slider-container {
        @apply px-0;
    }

    .wrapper {
        @apply w-full;
    }

    .slider {
        @apply mt-3;
    }

    &:deep() {
        .navigation-buttons {
            top: calc(
                (100% - #{$heading-height} - #{$sub-heading-height} - #{$headings-margin}) / 2 -
                    (#{$navigation-buttons-height / 2})
            );
        }
    }

    @screen lg {
        .slider-container {
            @apply px-5;
        }
    }
}
</style>
