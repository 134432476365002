<template>
    <RecommendedProductsSimpleSliderWrapper
        v-bind="propsToBind"
        :class="cssClass"
        @button-link-click="buttonLinkClick()"
        @loaded="onLoadedProducts($event)"
    />
</template>

<script>
import { RECO_BANNER_CLICK, RECO_BANNER_VIEW } from '@analytics-types/Events';

import { ITEMS_COUNT } from '@configs/simple-slider';

import { SYNERISE_RECOMMENDATION_TYPES } from '@types/Synerise';

import CmsBaseComponent from '@modules/page-builder/components/CmsBaseComponent/CmsBaseComponent';
import { getProps } from '@modules/page-builder/helpers/component';

import { getRecommendedSliderComponentVersion } from '@assets/pagebuilder';

import RecommendedProductsSimpleSliderWrapper from '@organisms/RecommendedProductsSimpleSliderWrapper/RecommendedProductsSimpleSliderWrapper';

export default {
    name: 'CmsRecommendedProductsSimpleSliderWrapper',

    components: {
        RecommendedProductsSimpleSliderWrapper,
    },

    extends: CmsBaseComponent,

    provide() {
        return {
            sliderNameOverride: this.sliderNameOverride || this.heading,
            sliderListOverride: this.sliderListOverride || `reco ${this.componentVersion}`,
        };
    },

    inject: {
        componentLoaded: {
            default: () => () => {},
        },

        sliderNameOverride: {
            default: '',
        },

        sliderListOverride: {
            default: '',
        },
    },

    props: getProps(RecommendedProductsSimpleSliderWrapper),

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    heading: '',
                    subheading: '',
                    buttonLink: '',
                    buttonLabel: '',
                    recommendationType: SYNERISE_RECOMMENDATION_TYPES.TYPE_CUSTOM,
                    productSku: '',
                    categoryBreadcrumbs: [],
                    itemsCount: 6,
                    customCampaignId: '',
                },
            },
            // eslint-disable-next-line vue/no-unused-properties
            options: {
                recommendationType: Object.values(SYNERISE_RECOMMENDATION_TYPES),
                itemsCount: Object.values(ITEMS_COUNT),
            },
        };
    },

    computed: {
        componentVersion() {
            return getRecommendedSliderComponentVersion(this.$props);
        },
    },

    methods: {
        buttonLinkClick() {
            const position = Object.keys(this.componentsList).indexOf(this.internalId);

            if (position >= 0) {
                this.$analytics.emit(RECO_BANNER_CLICK, {
                    version: this.componentVersion,
                    position,
                    campaingName: this.heading,
                });
            }
        },

        onLoadedProducts({ count }) {
            const position = Object.keys(this.componentsList).indexOf(this.internalId);

            if (position >= 0) {
                this.$analytics.emit(RECO_BANNER_VIEW, {
                    version: this.componentVersion,
                    position,
                    campaingName: this.heading,
                    offersCount: count,
                });
            }

            this.componentLoaded({
                count,
            });
        },
    },
};
</script>
