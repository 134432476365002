import { render, staticRenderFns } from "./MarketingSimplePictureBox.vue?vue&type=template&id=112a7da0&scoped=true&"
import script from "./MarketingSimplePictureBox.vue?vue&type=script&lang=js&"
export * from "./MarketingSimplePictureBox.vue?vue&type=script&lang=js&"
import style0 from "./MarketingSimplePictureBox.vue?vue&type=style&index=0&id=112a7da0&prod&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "112a7da0",
  null
  
)

export default component.exports