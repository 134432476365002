<template>
    <MarketingBar
        v-if="timeDiff > 0"
        v-bind="baseProps"
        class="marketing-bar-timer"
        @text-clicked="$emit('text-clicked')"
    >
        <template #after-text>
            <div
                ref="timer"
                :data-timestamp="timestampExpire"
                data-event-name="closeMarketingBarTimer"
                class="timer"
            >
                <template v-if="days > 0">
                    <span class="timer-part">{{ daysFormatted }} D</span>
                    <div class="separator">:</div>
                </template>
                <span class="timer-part">{{ hoursFormatted }} H</span>
                <div class="separator">:</div>
                <span class="timer-part">{{ minutesFormatted }} M</span>
                <div class="separator">:</div>
                <span class="timer-part">{{ secondsFormatted }} S</span>
            </div>
        </template>
    </MarketingBar>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { FORMAT_YYYYMMDD_HHMM } from '@types/DateFormat';

import SyncDateHelper from '@models/DateHelper/SyncDateHelper';

import MarketingBar from '@marketing-bar-components/molecules/MarketingBar/MarketingBar';

import { getTimeParts } from '@core-assets/countdown';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'MarketingBarTimer',

    components: {
        MarketingBar,
    },

    extends: MarketingBar,

    props: {
        date: {
            type: String,
            required: true,
        },
    },

    data() {
        return {
            days: 0,
            hours: 0,
            minutes: 0,
            seconds: 0,
        };
    },

    computed: {
        ...mapConfigGetters(['timezone']),

        timeDiff() {
            const dateHelper = new SyncDateHelper(this.timezone, FORMAT_YYYYMMDD_HHMM);

            const currentDate = dateHelper.createCurrentDateInTimezone();

            return Math.floor(this.timestampExpire - SyncDateHelper.toSeconds(currentDate));
        },

        daysFormatted() {
            return String(this.days).padStart(2, '0');
        },

        hoursFormatted() {
            return String(this.hours).padStart(2, '0');
        },

        minutesFormatted() {
            return String(this.minutes).padStart(2, '0');
        },

        secondsFormatted() {
            return String(this.seconds).padStart(2, '0');
        },

        timestampExpire() {
            const expireDate = SyncDateHelper.getDateFromFormat(
                this.date,
                FORMAT_YYYYMMDD_HHMM,
                this.timezone
            );

            return SyncDateHelper.toSeconds(expireDate) || 0;
        },
    },

    created() {
        this.assignTimeParts(getTimeParts(this.timeDiff));
    },

    mounted() {
        if (this.timeDiff > 0) {
            this.startCountdown();
        }

        window.addEventListener(
            'closeMarketingBarTimer',
            () => {
                this?.$emit('expired');
            },
            {
                once: true,
            }
        );
    },

    beforeDestroy() {
        this.countDownClock?.stop();
    },

    methods: {
        async startCountdown() {
            const { timer } = this.$refs;

            if (!(timer instanceof HTMLElement)) {
                return;
            }

            const { default: countDownClock } =
                (await import(
                    /* webpackChunkName: "countdown-clock" */ '@assets/countdown-clock'
                )) || {};

            this.countDownClock = countDownClock?.init(timer, false, timeParts => {
                this.assignTimeParts(timeParts);
            });
            this.countDownClock?.start();
        },

        assignTimeParts(timeParts) {
            const { days, hours, minutes, seconds } = timeParts;

            this.days = days;
            this.hours = hours;
            this.minutes = minutes;
            this.seconds = seconds;
        },
    },
};
</script>

<style lang="scss" scoped>
.marketing-bar-timer {
    @apply flex flex-wrap gap-x-2;

    .timer {
        @apply flex items-center justify-center;
    }

    .timer-part {
        @apply flex items-center justify-center w-7 h-5 font-book bg-light/20;
    }

    .separator {
        @apply px-2;
    }

    @screen mobile-only {
        .timer {
            @apply w-full;
        }
    }
}
</style>
