<template>
    <div class="marketing-image-tiles-simple-slider">
        <ContainerContent :align-left="true" class="slider-container">
            <BaseHeading class="heading" tag="h2">
                {{ heading }}
            </BaseHeading>

            <SimpleSlider :items-count="SLIDES_COUNT" :change-slide-by-one="false" class="slider">
                <template #slides>
                    <slot />
                </template>
            </SimpleSlider>
        </ContainerContent>
    </div>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import SimpleSlider from '@molecules/SimpleSlider/SimpleSlider';

export default {
    name: 'MarketingImageTilesSimpleSlider',

    components: {
        BaseHeading,
        ContainerContent,
        SimpleSlider,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },
    },

    beforeCreate() {
        this.SLIDES_COUNT = 6;
    },
};
</script>

<style lang="scss" scoped>
$item-heading-margin-top: 8px;
$item-heading-line-height: $tailwindcss-lineHeight-s;
$item-picture-height: calc(100% - #{$item-heading-line-height} - #{$item-heading-margin-top});
$navigation-buttons-height: 42px;

$navigation-top: calc(#{$item-picture-height} / 2 - #{$navigation-buttons-height / 2});

.marketing-image-tiles-simple-slider {
    .heading {
        @apply ml-3 text-new-m leading-new-m;
    }

    .slider-container {
        @apply px-0;
    }

    .slider {
        @apply mt-3;
    }

    &:deep() {
        .navigation-buttons {
            top: $navigation-top;
        }
    }

    @screen lg {
        .heading {
            @apply text-new-l leading-new-l;
        }

        .slider-container {
            @apply px-5;
        }
    }

    @screen container {
        .heading {
            @apply ml-0 text-xl leading-new-xl;
        }
    }
}
</style>
