import { render, staticRenderFns } from "./MarketingMultiBanner.vue?vue&type=template&id=2cd0d04e&scoped=true&"
import script from "./MarketingMultiBanner.vue?vue&type=script&lang=js&"
export * from "./MarketingMultiBanner.vue?vue&type=script&lang=js&"
import style0 from "./MarketingMultiBanner.vue?vue&type=style&index=0&id=2cd0d04e&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2cd0d04e",
  null
  
)

export default component.exports