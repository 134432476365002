<template>
    <Observer
        v-if="displayContainer && !disabled"
        :options="CONTAINER_OBSERVER_OPTIONS"
        :observe-once="true"
        class="recommended-products-simple-slider-wrapper"
        :class="{ 'is-loading': !isLoaded, 'with-heading': heading }"
        @intersect="displayContent($event)"
    >
        <template v-if="isDisplayed">
            <Loader v-if="!isLoaded" />
            <RecommendedProductsDataProvider
                :recommendation-type="recommendationType"
                :category-breadcrumbs="categoryBreadcrumbs"
                :product-sku="productSku"
                :custom-campaign-id="customCampaignId"
                :size="size"
                @loaded="onLoadedProducts($event)"
                @error="handleError()"
            >
                <component
                    :is="productsSimpleSliderWrapperComponent"
                    v-if="isLoaded"
                    :products="productsLoaded"
                    :items-count="itemsCount"
                    :heading="heading"
                    :subheading="subheading"
                    :button-link="buttonLink"
                    :button-label="buttonLabel"
                    @product-click="onProductClick($event)"
                    @button-link-click="$emit('button-link-click')"
                >
                    <template #after-price="{ product }">
                        <slot name="after-price" :product="product" />
                    </template>
                </component>
            </RecommendedProductsDataProvider>
        </template>
    </Observer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { ITEMS_COUNT } from '@configs/simple-slider';
import { PRODUCT_SLIDER_SLIDE_ATTRIBUTE } from '@configs/product';
import { DEFAULT_DEBOUNCE_TIME } from '@configs/recommendations';

import { ERROR_ACTION_TAG_NAME } from '@types/Errors';
import { SYNERISE_RECOMMENDATION_TYPES } from '@types/Synerise';

import { IMPRESSION_CLICK, IMPRESSION_VIEW } from '@analytics-types/Events';

import { PRODUCT_RECOMMENDATION_SLIDER_VIEW } from '@analytics-module/modules/product/types/Events';
import { MODULE_NAME as PRODUCT_MODULE_NAME } from '@analytics-module/modules/product/meta';

import { debounceAggregate } from '@assets/debounce-aggregate';
import { mapProductToDataLayerProduct } from '@assets/recommendations';
import { checkIfExistsInValuesMap } from '@assets/props';

import Loader from '@atoms/Loader/Loader';
import Observer from '@atoms/Observer/Observer';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

const ERROR = 'error';

export default {
    name: 'RecommendedProductsSimpleSliderWrapper',

    components: {
        Loader,
        Observer,
        RecommendedProductsDataProvider: () => ({
            component: import(
                /* webpackChunkName: "recommended-products-data-provider" */
                '@molecules/RecommendedProductsDataProvider/RecommendedProductsDataProvider'
            ),
        }),
    },

    inject: {
        sliderNameOverride: {
            default: null,
        },

        sliderListOverride: {
            default: null,
        },
    },

    props: {
        heading: {
            type: String,
            default: '',
        },

        subheading: {
            type: String,
            default: '',
        },

        buttonLabel: {
            type: String,
            default: '',
        },

        buttonLink: {
            type: String,
            default: '',
        },

        recommendationType: {
            type: String,
            required: true,
            validator: checkIfExistsInValuesMap(SYNERISE_RECOMMENDATION_TYPES),
        },

        categoryBreadcrumbs: {
            type: Array,
            default: () => [],
        },

        productSku: {
            type: [String, Array],
            default: '',
        },

        itemsCount: {
            type: Number,
            default: 6,
            validator: value => ITEMS_COUNT.includes(value),
        },

        customCampaignId: {
            type: String,
            default: '',
        },

        size: {
            type: String,
            default: '',
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isDisplayed: false,
            productsSimpleSliderWrapperComponent: null,
            displayContainer: true,
            slideObserver: null,
            productsLoaded: [],
        };
    },

    computed: {
        ...mapConfigGetters(['currency']),

        isLoaded() {
            return this.productsLoaded.length && this.productsSimpleSliderWrapperComponent;
        },

        sliderName() {
            return this.sliderListOverride || this.recommendationType;
        },

        campaignNameVisible() {
            return this.sliderNameOverride || this.heading;
        },
    },

    watch: {
        async isLoaded(value) {
            if (!value) {
                return;
            }

            this.slideObserver = new IntersectionObserver(
                entries => {
                    const entriesIntersecting = entries.filter(
                        ({ isIntersecting }) => isIntersecting
                    );

                    if (!entriesIntersecting.length) {
                        return;
                    }

                    entriesIntersecting.forEach(({ target }) => {
                        const index = parseInt(
                            target.getAttribute(PRODUCT_SLIDER_SLIDE_ATTRIBUTE),
                            10
                        );

                        const product = this.productsLoaded[index];

                        this.debouncedOnProductView({ product, index });
                        this.slideObserver.unobserve(target);
                    });
                },
                {
                    threshold: 0.5,
                }
            );

            await this.$nextTick();

            const slides = this.$el.querySelectorAll(`[${PRODUCT_SLIDER_SLIDE_ATTRIBUTE}]`);

            slides.forEach(slide => {
                this.slideObserver.observe(slide);
            });
        },
    },

    beforeCreate() {
        this.CONTAINER_OBSERVER_OPTIONS = {
            root: null,
            threshold: 0,
            rootMargin: '0px 0px 200px 0px',
        };

        this.debouncedOnProductView = debounceAggregate(products => {
            this.sendProductViewsToAnalytics(products);
        }, DEFAULT_DEBOUNCE_TIME);
    },

    beforeDestroy() {
        this.slideObserver?.disconnect();
        this.slideObserver = null;
    },

    methods: {
        displayContent(intersect) {
            if (intersect) {
                this.isDisplayed = true;
                this.loadProductsSimpleSliderWrapperComponent();
            }
        },

        loadProductsSimpleSliderWrapperComponent() {
            import(
                /* webpackChunkName: "products-simple-slider-wrapper" */
                '@molecules/ProductsSimpleSliderWrapper/ProductsSimpleSliderWrapper'
            )
                .then(component => {
                    this.productsSimpleSliderWrapperComponent = component.default;
                })
                .catch(err => {
                    this.handleError();
                    this.$errorHandler.captureError(err, {
                        [ERROR_ACTION_TAG_NAME]: 'lazyload',
                    });
                });
        },

        handleError() {
            this.displayContainer = false;
            this.isDisplayed = false;
            this.$emit('error');
            this.emitRecommendationSliderViewEvent(ERROR);
        },

        emitRecommendationSliderViewEvent(productsLength) {
            this.$analytics.moduleEmit(PRODUCT_MODULE_NAME, PRODUCT_RECOMMENDATION_SLIDER_VIEW, {
                sliderName: this.sliderName,
                offersCount: productsLength,
            });
        },

        onLoadedProducts({ products }) {
            this.productsLoaded = products;

            const { length: count } = products;

            if (!count) {
                this.displayContainer = false;
            }

            if (!this.sliderListOverride) {
                this.emitRecommendationSliderViewEvent(count);
            }

            this.$emit('loaded', { count, products });
        },

        onProductClick({ product, index }) {
            const analyticsProduct = mapProductToDataLayerProduct({
                product: { ...product, recommendationCampaignName: this.campaignNameVisible },
                index,
            });

            this.$analytics.emit(IMPRESSION_CLICK, {
                currency: this.currency,
                product: analyticsProduct,
                actionFieldList: this.sliderName,
                route: this.$route,
            });
        },

        sendProductViewsToAnalytics(products) {
            const productsToSend = products.flat().map(({ product, index }) => {
                if (product?.categories?.[0]) {
                    product.category = product?.categories[0].split('/').slice(1).join('/') || '';
                }

                return {
                    ...mapProductToDataLayerProduct({
                        product: {
                            ...product,
                            recommendationCampaignName: this.campaignNameVisible,
                        },
                        index,
                    }),
                    list: this.sliderName,
                };
            });

            this.$analytics.emit(IMPRESSION_VIEW, {
                currency: this.currency,
                products: productsToSend,
                campaignId: this.customCampaignId,
                campaignHash: '',
                route: this.$route,
            });
        },
    },
};
</script>

<style lang="scss" scoped>
$heading-height-mobile: 28px + $tailwindcss-spacing-3;
$heading-height-tablet: 32px + $tailwindcss-spacing-3;
$heading-height-lg-min: 40px + $tailwindcss-spacing-3;

$min-height-mobile: 331px;
$min-height-lg-min: 321px;
$min-height-container: 409px;

$min-height-with-heading-mobile: $min-height-mobile + $heading-height-mobile;
$min-height-with-heading-tablet: $min-height-lg-min + $heading-height-tablet;
$min-height-with-heading-container: $min-height-container + $heading-height-lg-min;

$multiplier: 100px / 22px;

@mixin min-height-lg-min($min-height, $multiplier) {
    min-height: calc(#{$min-height} + ((100vw - 1024px) / #{$multiplier}));
}

.recommended-products-simple-slider-wrapper {
    @apply flex items-center;
    min-height: $min-height-mobile;

    &.with-heading {
        min-height: $min-height-with-heading-mobile;
    }

    &.is-loading {
        @apply bg-gray4;
    }

    @screen md {
        @include min-height-lg-min($min-height-lg-min, $multiplier);

        &.with-heading {
            @include min-height-lg-min($min-height-with-heading-tablet, $multiplier);
        }
    }

    @screen container {
        min-height: $min-height-container;

        &.with-heading {
            min-height: $min-height-with-heading-container;
        }
    }
}
</style>
