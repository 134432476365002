<template>
    <div v-if="!disabled" :style="styles" class="marketing-multi-banner">
        <ContainerContent :class="{ 'has-slider': hasSlider }" class="banner-wrapper">
            <div v-if="isDesktopMediaVisible" class="banner-media desktop-media">
                <MarketingMultiBannerMedia
                    :media-height="333"
                    :media-width="593"
                    :media-src="desktopMediaLeftSrc"
                    :is-left="true"
                    :is-mobile="isMobile"
                    :is-video="isDesktopMediaLeftVideo"
                    :is-lazy="isMediaLazyLoaded"
                    class="desktop-media-left"
                />
                <MarketingMultiBannerMedia
                    :media-height="533"
                    :media-width="400"
                    :media-src="desktopMediaRightSrc"
                    :is-mobile="isMobile"
                    :is-video="isDesktopMediaRightVideo"
                    :is-lazy="isMediaLazyLoaded"
                    class="desktop-media-right"
                />
            </div>
            <div v-if="isMobileMediaVisible" class="banner-media mobile-media">
                <MarketingMultiBannerMedia
                    :media-height="959"
                    :media-width="767"
                    :media-src="mobileMediaSrc"
                    :is-mobile="isMobile"
                    :is-video="isMobileMediaVideo"
                    :is-lazy="isMediaLazyLoaded"
                />
            </div>
            <MarketingMultiBannerContent
                :theme="theme"
                :heading="heading"
                :description="description"
                :button-label="buttonLabel"
                :button-variant="buttonVariant"
                class="media-content"
            />
            <component
                :is="componentTag"
                :path="url"
                class="link"
                @click.native="$emit('link-click')"
            />
        </ContainerContent>
        <div v-if="hasSlider" class="slider">
            <slot name="slider" />
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

import { THEMES } from '@types/MarketingBlocks';

import { checkIfExistsInValuesMap } from '@assets/props';
import { isHttpLink } from '@assets/link';

import BaseLinkStatic from '@atoms/BaseLinkStatic/BaseLinkStatic';
import BaseLink from '@atoms/BaseLink/BaseLink';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import MarketingMultiBannerContent from '@molecules/MarketingMultiBannerContent/MarketingMultiBannerContent';
import MarketingMultiBannerMedia from '@molecules/MarketingMultiBannerMedia/MarketingMultiBannerMedia';

import { BUTTON_LARGE_VARIANTS } from '@modivo-ui/components/ButtonLarge/v1';

export default {
    name: 'MarketingMultiBanner',

    components: {
        ContainerContent,
        MarketingMultiBannerContent,
        MarketingMultiBannerMedia,
        BaseLinkStatic,
        BaseLink,
    },

    provide() {
        return {
            backgroundColor: this.backgroundColor,
        };
    },

    props: {
        url: {
            type: String,
            required: true,
        },

        desktopMediaLeftSrc: {
            type: String,
            required: true,
        },

        desktopMediaRightSrc: {
            type: String,
            required: true,
        },

        mobileMediaSrc: {
            type: String,
            required: true,
        },

        isDesktopMediaLeftVideo: {
            type: Boolean,
            default: false,
        },

        isDesktopMediaRightVideo: {
            type: Boolean,
            default: false,
        },

        isMobileMediaVideo: {
            type: Boolean,
            default: false,
        },

        heading: {
            type: String,
            default: '',
        },

        description: {
            type: String,
            default: '',
        },

        buttonLabel: {
            type: String,
            default: '',
        },

        backgroundColor: {
            type: String,
            default: '#0000',
        },

        theme: {
            type: String,
            default: THEMES.THEME_DARK,
            validator: checkIfExistsInValuesMap(THEMES),
        },

        buttonVariant: {
            type: String,
            default: BUTTON_LARGE_VARIANTS.SECONDARY,
            validator: checkIfExistsInValuesMap(BUTTON_LARGE_VARIANTS),
        },

        isMediaLazyLoaded: {
            type: Boolean,
            default: true,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            isMobileMediaVisible: true,
            isDesktopMediaVisible: true,
            isMobileWatcher: null,
        };
    },

    computed: {
        ...mapState(['isMobile']),

        componentTag() {
            return isHttpLink(this.url) ? BaseLinkStatic : BaseLink;
        },

        hasSlider() {
            return this.$slots.slider;
        },

        styles() {
            return {
                '--marketing-multibanner-background-color': this.backgroundColor,
            };
        },
    },

    mounted() {
        this.isMobileWatcher = this.$watch(
            'isMobile',
            () => {
                this.isMobileMediaVisible = this.isMobile;
                this.isDesktopMediaVisible = !this.isMobile;
            },
            { immediate: true }
        );
    },

    beforeDestroy() {
        this.isMobileWatcher?.();
    },
};
</script>

<style lang="scss" scoped>
$banner-wrapper-max-height: 533px;
$background-height-lg: 278px;
$background-height-xl: 346px;
$background-height-container: 433px;
$desktop-media-left-max-width: 593px;
$desktop-media-right-max-width: 400px;
$mobile-slide-min-width: 40%;

@mixin backgroundHeight($color, $height) {
    background-image: linear-gradient(
        0deg,
        transparent calc(50% - #{$height}),
        $color calc(50% - #{$height}),
        $color calc(50% + #{$height}),
        transparent calc(50% + #{$height}),
        transparent 100%
    );
}

@mixin backgroundFluidHeight($color, $valueMin, $valueMax, $viewportMin, $viewportMax) {
    $valueDiff: #{($valueMax - $valueMin) / 1px};
    $viewportDiff: #{($viewportMax - $viewportMin) / 1px};

    $factor: calc(#{($valueDiff / $viewportDiff)} * 100vw);
    $base: #{($viewportMin * $valueMax - $viewportMax * $valueMin) / ($viewportMin - $viewportMax)};

    $half-height: clamp(#{$valueMin / 2}, #{$base / 2} + #{$factor / 2}, #{$valueMax / 2});

    @include backgroundHeight($color, $half-height);
}

$backgroundColor: var(--marketing-multibanner-background-color);

.marketing-multi-banner {
    .banner-wrapper {
        @apply w-full relative;
    }

    .banner-media {
        @apply w-full;
    }

    .link {
        @apply absolute top-0 left-0 w-full h-full;
    }

    .slider {
        @apply relative;
    }

    &:deep(.products-simple-slider-wrapper) {
        .slider-container {
            @apply pt-0 pb-3;
        }

        .heading-wrapper {
            @apply mb-0 hidden;
        }

        .products-simple-slider-slide {
            @apply pt-0 px-0 mr-3;

            min-width: $mobile-slide-min-width;
        }
    }

    @screen mobile-only {
        @apply pt-4;

        .mobile-media {
            @apply -mt-4;
        }

        .desktop-media {
            @apply hidden;
        }

        .slider {
            &:not(:empty) {
                @apply -mt-7;
            }
        }

        .banner-wrapper {
            background: #{$backgroundColor};

            &.has-slider {
                @apply pb-7;
            }
        }
    }

    @screen lg {
        --product-tile-right-margin: 24px;

        .banner-wrapper {
            @include backgroundFluidHeight(
                $backgroundColor,
                $background-height-lg,
                $background-height-xl,
                1024px,
                1280px
            );
            @apply px-5 flex flex-row-reverse justify-between w-full h-full gap-3;

            max-height: $banner-wrapper-max-height;
        }

        .media-content {
            @apply my-auto;
        }

        .banner-media {
            @apply flex flex-1 justify-end;
        }

        .mobile-media {
            @apply hidden;
        }

        .desktop-media {
            @apply gap-3;
        }

        .desktop-media-left {
            @apply my-auto;

            width: 58.54%;
            max-width: $desktop-media-left-max-width;
        }

        .desktop-media-right {
            width: 38.92%;
            max-width: $desktop-media-right-max-width;
        }

        &:deep(.products-simple-slider-wrapper) {
            .slider-container {
                @apply pt-3;
            }

            .slider-container-content {
                @apply px-30p;
            }

            .products-simple-slider-slide {
                margin-right: var(--product-tile-right-margin);
                min-width: calc(16.6666% - var(--product-tile-right-margin) / 6 * 5);
            }
        }
    }

    @screen xl {
        .banner-wrapper {
            @include backgroundFluidHeight(
                $backgroundColor,
                $background-height-xl,
                $background-height-container,
                1280px,
                1596px
            );
        }

        .desktop-media-left {
            width: 58.6%;
        }

        .desktop-media-right {
            width: 39.4%;
        }
    }

    @screen container {
        --product-tile-right-margin: 38px;

        .banner-wrapper {
            @include backgroundHeight($backgroundColor, $background-height-container / 2);
        }

        .desktop-media-left {
            width: 58.73%;
        }

        .desktop-media-right {
            width: 39.68%;
        }
    }
}
</style>
