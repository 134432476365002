<template>
    <div />
</template>

<script>
import { DEFAULT_ZOWIE_CHAT_SRC } from '@assets/zowie-chat';

let AVAILABLE_PAGES = [];

export default {
    name: 'CmsGlobalConfig',

    props: {
        // eslint-disable-next-line vue/no-unused-properties
        optimizelyEnabledPages: {
            type: Array,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        newsletterPromoText: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        newsletterFooterTerms: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        isMagicLinkEnabled: {
            type: Boolean,
        },

        // eslint-disable-next-line vue/no-unused-properties
        isZowieChatEnabled: {
            type: Boolean,
        },

        // eslint-disable-next-line vue/no-unused-properties
        zowieChatInstanceId: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        zowieScriptSrc: {
            type: String,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        zowieChatEnabledPages: {
            type: Array,
            required: true,
        },

        // eslint-disable-next-line vue/no-unused-properties
        freeShippingTresholdInternal: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        freeShippingTresholdPartner: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        minimalShippingCostInternal: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        shippingTimeInfo: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        isAvatarClientAddressEnabled: {
            type: Boolean,
            default: false,
        },

        // eslint-disable-next-line vue/no-unused-properties
        isAccountVerificationEnabled: {
            type: Boolean,
        },

        // eslint-disable-next-line vue/no-unused-properties
        freeDeliveryText: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        synthroneScriptUrl: {
            type: String,
            default: '',
        },

        // eslint-disable-next-line vue/no-unused-properties
        synthroneBrands: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            // eslint-disable-next-line vue/no-unused-properties
            defaultConfig: {
                props: {
                    optimizelyEnabledPages: [],
                    isMagicLinkEnabled: false,
                    isZowieChatEnabled: false,
                    zowieChatEnabledPages: [],
                    zowieChatInstanceId: '',
                    zowieScriptSrc: DEFAULT_ZOWIE_CHAT_SRC,
                    freeShippingTresholdInternal: '',
                    freeShippingTresholdPartner: '',
                    minimalShippingCostInternal: '',
                    shippingTimeInfo: '',
                    isAvatarClientAddressEnabled: false,
                    isAccountVerificationEnabled: false,
                    freeDeliveryText: '',
                    synthroneScriptUrl: '',
                    synthroneBrands: '',
                },
            },

            // eslint-disable-next-line vue/no-unused-properties
            options: {
                optimizelyEnabledPages: AVAILABLE_PAGES,
                zowieChatEnabledPages: AVAILABLE_PAGES,
            },
        };
    },

    beforeCreate() {
        AVAILABLE_PAGES = this.$router.options.routes
            .map(item => [item, ...(item?.children || [])].filter(item2 => item2?.name))
            .flat()
            .map(({ name }) => name);
    },
};
</script>
