<template>
    <ContainerContent class="marketing-video-banner">
        <div class="banner-container">
            <div class="video-wrapper">
                <IframeVideo
                    class="video"
                    :width="960"
                    :height="540"
                    :is-lazy="true"
                    :src="videoSrc"
                />
            </div>

            <MarketingHeroContent
                class="content"
                :heading="heading"
                :description="description"
                :button-label="buttonLabel"
                :button-link="buttonLink"
            />
        </div>
    </ContainerContent>
</template>

<script>
import IframeVideo from '@atoms/IframeVideo/IframeVideo';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';

import MarketingHeroContent from '@organisms/MarketingHeroContent/MarketingHeroContent';

export default {
    name: 'MarketingVideoBanner',

    components: {
        MarketingHeroContent,
        IframeVideo,
        ContainerContent,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        description: {
            type: String,
            required: true,
        },

        buttonLink: {
            type: String,
            required: true,
        },

        buttonLabel: {
            type: String,
            required: true,
        },

        videoSrc: {
            type: String,
            required: true,
        },
    },
};
</script>

<style lang="scss" scoped>
$container-width: 1596px;
$video-max-width: 960px;
$content-margin-left: 66px;

.marketing-video-banner {
    .video-wrapper {
        @apply w-full;
        max-width: $video-max-width;
    }

    .content {
        @apply mt-3;
    }

    .banner-container {
        @apply w-full;
    }

    @screen md {
        @apply bg-gray5;

        .banner-container {
            @apply flex p-5 w-full items-center;
            max-width: $container-width;
        }

        .video-wrapper {
            @apply w-3/5;
        }

        .content {
            @apply self-center mt-0 w-2/5;
            margin-left: $content-margin-left;
        }

        &:deep() {
            .description {
                @apply mt-2;
            }
        }
    }

    @screen lg {
        @apply px-0 max-w-full;

        .banner-container {
            @apply px-3;
        }
    }

    @screen xxl {
        .video-wrapper {
            @apply w-full;
        }

        .content {
            @apply w-auto;
        }
    }
}
</style>
