<template>
    <Observer
        v-if="isContainerDisplayed"
        :options="OBSERVER_OPTIONS"
        :observe-once="true"
        class="featured-slider-wrapper"
        @intersect="displayContent($event)"
    >
        <Loader v-if="isLoading" />
        <PromotedSlider
            v-else
            :heading="heading"
            :products="sponsoredProducts"
            :placement-page-name="placementPageName"
        />
    </Observer>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

import { ASYNC_AD_TECH_SERVICE_CONFIG } from '@async-services/adTech/meta';

import {
    NAME_ATTRIBUTE_NAME,
    NAME_DISPLAY_ATTRIBUTE_NAME,
    SKU_ATTRIBUTE_NAME,
    BRAND_ATTRIBUTE_NAME,
    URL_KEY_ATTRIBUTE_NAME,
    PRICE_ATTRIBUTE_NAME,
    IMAGES_ATTRIBUTE_NAME,
    DISCOUNT_ATTRIBUTE_NAME,
    COLOR_ARRAY_ATTRIBUTE_NAME,
    FINAL_PRICE_ATTRIBUTE_NAME,
    HOT_DEAL_ATTRIBUTE_NAME,
    STICKER_ATTRIBUTE_NAME,
    SPECIAL_ACTION_ATTRIBUTE_NAME,
    OMNIBUS_PRICE_ATTRIBUTE_NAME,
    OMNIBUS_DISCOUNT_ATTRIBUTE_NAME,
} from '@configs/product-attribute-names';

import { getSelectLocales } from '@assets/locale';

import Observer from '@atoms/Observer/Observer';
import Loader from '@atoms/Loader/Loader';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

const {
    NAME: AD_TECH_SERVICE_NAME,
    METHODS: { NOTIFY_BEACON },
} = ASYNC_AD_TECH_SERVICE_CONFIG;

export default {
    name: 'PromotedSliderWrapper',

    components: {
        Loader,
        Observer,
        PromotedSlider: () => ({
            component: import(
                /* webpackChunkName: "promoted-slider" */
                '@organisms/PromotedSlider/PromotedSlider'
            ),
        }),
    },

    inject: {
        category: {
            default: '',
        },
    },

    props: {
        placementPageName: {
            type: String,
            required: true,
        },

        heading: {
            type: String,
            required: true,
        },

        indexes: {
            type: Array,
            default: () => [],
        },

        productUrl: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            isContainerDisplayed: true,
            isLoading: true,
            sponsoredProducts: [],
            onLoadBeacons: [],
            onViewBeacons: [],
            wrapperObserver: null,
        };
    },

    computed: {
        ...mapConfigGetters(['currency', 'locale', 'timezone']),

        selectLocales() {
            return getSelectLocales(this.locale);
        },
    },

    beforeCreate() {
        this.OBSERVER_OPTIONS = {
            root: null,
            threshold: 0,
            rootMargin: '0px 0px 200px 0px',
        };

        this.SPONSORED_QUERY_FIELDS = [
            NAME_ATTRIBUTE_NAME,
            NAME_DISPLAY_ATTRIBUTE_NAME,
            SKU_ATTRIBUTE_NAME,
            BRAND_ATTRIBUTE_NAME,
            URL_KEY_ATTRIBUTE_NAME,
            PRICE_ATTRIBUTE_NAME,
            IMAGES_ATTRIBUTE_NAME,
            DISCOUNT_ATTRIBUTE_NAME,
            COLOR_ARRAY_ATTRIBUTE_NAME,
            FINAL_PRICE_ATTRIBUTE_NAME,
            HOT_DEAL_ATTRIBUTE_NAME,
            STICKER_ATTRIBUTE_NAME,
            SPECIAL_ACTION_ATTRIBUTE_NAME,
            OMNIBUS_PRICE_ATTRIBUTE_NAME,
            OMNIBUS_DISCOUNT_ATTRIBUTE_NAME,
        ];
    },

    mounted() {
        this.wrapperObserver = new IntersectionObserver(
            ([entry]) => {
                if (!entry?.isIntersecting) {
                    return;
                }

                this.onViewBeacons.forEach(beacon => {
                    this.$asyncServices.use(AD_TECH_SERVICE_NAME, NOTIFY_BEACON, { beacon });
                });

                this.removeWrapperObserver();
            },
            {
                root: null,
                threshold: 0,
                rootMargin: '0px 0px 0px 0px',
            }
        );
    },

    beforeDestroy() {
        this.removeWrapperObserver();
    },

    methods: {
        async displayContent(intersect) {
            if (!intersect) {
                return;
            }

            await this.loadSponsored();

            const count = this.sponsoredProducts.length;

            if (count <= 0) {
                this.isContainerDisplayed = false;

                return;
            }

            this.isLoading = false;
            this.$emit('sponsored-loaded', count);

            this.onLoadBeacons.forEach(beacon => {
                this.$asyncServices.use(AD_TECH_SERVICE_NAME, NOTIFY_BEACON, { beacon });
            });

            this.wrapperObserver?.observe(this.$el);
        },

        async getSponsoredHomepage() {
            const {
                locale,
                currency,
                selectLocales,
                category,
                SPONSORED_QUERY_FIELDS: fields,
            } = this;

            return this.$services.product.getSponsoredProductsBase({
                locale,
                currency,
                selectLocales,
                fields,
                category,
            });
        },

        async getSponsoredByIndexes() {
            const {
                indexes,
                locale,
                currency,
                selectLocales,
                SPONSORED_QUERY_FIELDS: fields,
            } = this;

            return this.$services.product.getSponsoredProductsBase({
                indexes,
                locale,
                currency,
                selectLocales,
                fields,
            });
        },

        async getSponsoredByUrlKey() {
            const {
                productUrl: urlKey,
                locale,
                currency,
                selectLocales,
                SPONSORED_QUERY_FIELDS: fields,
            } = this;

            return this.$services.product.getSponsoredProductsBase({
                urlKey,
                locale,
                currency,
                selectLocales,
                fields,
            });
        },

        async loadSponsored() {
            let getFunction = this.getSponsoredHomepage;

            if (this.indexes.length > 0) {
                getFunction = this.getSponsoredByIndexes;
            } else if (this.productUrl) {
                getFunction = this.getSponsoredByUrlKey;
            }

            const { products, sponsorshipDetails } = await getFunction();

            this.sponsoredProducts = products;

            const {
                load_event_urls: onLoadBeacons = [],
                view_event_urls: onViewBeacons = [],
            } = sponsorshipDetails;

            this.onLoadBeacons = onLoadBeacons;
            this.onViewBeacons = onViewBeacons;
        },

        removeWrapperObserver() {
            this.wrapperObserver?.disconnect();
            this.wrapperObserver = null;
        },
    },
};
</script>

<style lang="scss" scoped>
$heading-height-mobile: 28px;
$heading-height-tablet: 36px;
$heading-height-lg-min: 40px;

$min-height-mobile: 317px;
$min-height-lg-min: 361px;
$min-height-container: 475px;

$multiplier: 100px / 22px;

$lg-breakpoint: 1024px;

@mixin min-height-lg-min($min-height, $multiplier) {
    min-height: calc(#{$min-height} + ((100vw - #{$lg-breakpoint}) / #{$multiplier}));
}

// Class of the component can be modified to circumvent filters blocking sponsored content
.featured-slider-wrapper {
    @apply flex items-center;
    min-height: $min-height-mobile;

    @screen md {
        @include min-height-lg-min($min-height-lg-min, $multiplier);
    }

    @screen container {
        min-height: $min-height-container;
    }
}
</style>
