<template>
    <section v-if="!disabled" class="marketing-cards-slider-2">
        <ContainerContent :align-left="true" class="slider-container">
            <div class="wrapper">
                <BaseHeading class="heading">
                    {{ heading }}
                </BaseHeading>

                <SimpleSlider
                    :items-count="SLIDES_COUNT"
                    :additional-scroll-offset="32"
                    class="slider"
                >
                    <template #slides>
                        <slot></slot>
                    </template>
                </SimpleSlider>
            </div>
        </ContainerContent>
    </section>
</template>

<script>
import BaseHeading from '@atoms/BaseHeading/BaseHeading';

import ContainerContent from '@molecules/ContainerContent/ContainerContent';
import SimpleSlider from '@molecules/SimpleSlider/SimpleSlider';

export default {
    name: 'MarketingCardsSlider2',

    components: {
        ContainerContent,
        SimpleSlider,
        BaseHeading,
    },

    props: {
        heading: {
            type: String,
            required: true,
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    beforeCreate() {
        this.SLIDES_COUNT = 3;
    },
};
</script>

<style lang="scss" scoped>
$heading-line-height-mobile: $tailwindcss-lineHeight-new-m;
$heading-line-height-desktop: $tailwindcss-lineHeight-new-l;
$subheading-line-height: $tailwindcss-lineHeight-r;
$headings-margin-top: $tailwindcss-spacing-4;

$navigation-buttons-height: $tailwindcss-lineHeight-xl;

@function navButtonTop($headingLineHeight) {
    @return calc(
        (100% - #{$headingLineHeight} - #{$subheading-line-height} - #{$headings-margin-top}) / 2 -
            (#{$navigation-buttons-height / 2})
    );
}

.marketing-cards-slider-2 {
    @apply w-full py-4;

    .heading {
        @apply text-l leading-l mb-4;
    }

    .slider-container {
        @apply pl-3 pr-0;
    }

    .wrapper {
        @apply w-full;
    }

    .slider {
        @apply mt-4;
    }

    &:deep(.navigation-buttons) {
        &.horizontal-nav {
            top: navButtonTop($heading-line-height-mobile);
        }
    }

    &:deep(.marketing-cards-slider-2-slide) {
        &:first-child {
            @apply ml-0;
        }
    }

    @screen mobile-only {
        &:deep(.slider) {
            .wrapper {
                scroll-padding-left: 16px;
            }
        }
    }

    @screen lg {
        .heading {
            @apply px-0 text-xl leading-xl mb-5;
        }

        .slider-container {
            @apply px-5;
        }

        .slider {
            @apply mt-5;
        }

        &:deep(.navigation-buttons) {
            &.horizontal-nav {
                top: navButtonTop($heading-line-height-desktop);
            }
        }
    }

    @screen container {
        .heading {
            @apply text-xl leading-new-xl;
        }
    }
}
</style>
